import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';

// TODO: refresh licence for v24
// https://js.devexpress.com/Angular/Documentation/Guide/Common/Licensing/#How_to_Obtain_and_Validate_Your_License_Key
config({ licenseKey });

if (getProduct() === 'wl') {
  window.wl = {
    applicationLink: getApplicationLink(),
  };
}

import('./bootstrap');
